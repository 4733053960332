<template>
  <!--suppress RequiredAttributes -->
  <Tabulator
      :activeColumns="activeColumns"
      class="tabulator--entityTabulator tabulator--vehicleTrailers"
      @tabulator:created="handleTabulatorCreated"
      @tabulator:filtered="adjustColumnsOnFilters"
  />
</template>
<script setup>


import Tabulator from "@/components/Tabulator/Tabulator.vue";
import {ref} from "vue";

const props = defineProps({
  activeColumns: {
    type: Array,
    default: () => [],
  },
});

const columnSets = {
  STOCK: [
    'image',
    'image_360',
    'chassis_manufacturer',
    'vehicle_trailer_type',
    'year',
    'stock_no',
    'description',
    'cond',
    'supplier_cost_total',
    'customer_base_cost',
    'profit',
    'mot_due',
    'jobs',
    'stock_location',
    'last_change_status_date',
    'check_status'
  ],
  HIRE: [
    'image',
    'image_360',
    'chassis_manufacturer',
    'vehicle_trailer_type',
    'year',
    'stock_no',
    'owner',
    'description',
    'cond',
    'hires__company',
    'hires__start',
    'gross_hire_cost',
    'hire_rate',
    'hire_profit',
    'supplier_cost_total',
    'customer_base_cost',
    'mot_due',
    'jobs',
    'stock_location',
    'last_change_status_date',
    'check_status'
  ],
  HIRE_RESERVE: [
    'image',
    'image_360',
    'chassis_manufacturer',
    'vehicle_trailer_type',
    'year',
    'stock_no',
    'owner',
    'description',
    'cond',
    'hires__company',
    'hires__start',
    'gross_hire_cost',
    'hire_rate',
    'hire_profit',
    'supplier_cost_total',
    'customer_base_cost',
    'mot_due',
    'jobs',
    'stock_location',
    'last_change_status_date',
    'check_status'
  ],
  PROD: [
    'image',
    'image_360',
    'chassis_manufacturer',
    'vehicle_trailer_type',
    'year',
    'stock_no',
    'prod_no',
    'owner',
    'description',
    'cond',
    'factory_wk',
    'factory_delayed_wk',
    'earliest_wk',
    'third_wk',
    'all_wks',
    'promise_wk',
    'supplier_cost_total',
    'customer_base_cost',
    'profit',
    'conf',
    'po_status',
    'sale',
    'finance_estimate',
    'invoice_status',
    'financial_estimate_status',
    'mot_due',
    'jobs',
    'stock_location',
    'last_change_status_date',
    'check_status'
  ],
  PROD_PAID: [
    'image',
    'image_360',
    'chassis_manufacturer',
    'vehicle_trailer_type',
    'year',
    'stock_no',
    'prod_no',
    'owner',
    'description',
    'cond',
    'factory_wk',
    'factory_delayed_wk',
    'earliest_wk',
    'third_wk',
    'all_wks',
    'promise_wk',
    'supplier_cost_total',
    'customer_base_cost',
    'profit',
    'conf',
    'po_status',
    'sale',
    'finance_estimate',
    'invoice_status',
    'financial_estimate_status',
    'mot_due',
    'jobs',
    'stock_location',
    'last_change_status_date',
    'check_status'
  ],
  SOLD_AWAIT_PAY: [
    'image',
    'image_360',
    'chassis_manufacturer',
    'vehicle_trailer_type',
    'year',
    'stock_no',
    'prod_no',
    'owner',
    'description',
    'cond',
    'supplier_cost_total',
    'customer_base_cost',
    'profit',
    'conf',
    'po_status',
    'sale',
    'finance_estimate',
    'invoice_status',
    'financial_estimate_status',
    'mot_due',
    'jobs',
    'stock_location',
    'last_change_status_date',
    'check_status'
  ],
  SOLD_AWAIT_COL: [
    'image',
    'image_360',
    'chassis_manufacturer',
    'vehicle_trailer_type',
    'year',
    'stock_no',
    'owner',
    'description',
    'cond',
    'supplier_cost_total',
    'customer_base_cost',
    'profit',
    'conf',
    'po_status',
    'sale',
    'finance_estimate',
    'invoice_status',
    'financial_estimate_status',
    'mot_due',
    'jobs',
    'stock_location',
    'last_change_status_date',
    'check_status'
  ],
  WORKSHOP_HIRE: [
    'image',
    'image_360',
    'chassis_manufacturer',
    'vehicle_trailer_type',
    'year',
    'stock_no',
    'owner',
    'description',
    'cond',
    'hires__company',
    'hires__start',
    'gross_hire_cost',
    'hire_rate',
    'hire_profit',
    'supplier_cost_total',
    'customer_base_cost',
    'mot_due',
    'jobs',
    'stock_location',
    'last_change_status_date',
    'check_status'
  ],
}

const activeColumns = ref(columnSets.STOCK);

const tabulatorTable = ref(null);

function handleTabulatorCreated(_tabulator) {
  tabulatorTable.value = _tabulator;
}

function adjustColumnsOnFilters(filters) {

  function setColumnsBasedOnStatus(status) {
    activeColumns.value = columnSets[status];
  }


  filters.forEach((filter) => {
    if (filter.field === 'status' && filter.type === '=') {
      setColumnsBasedOnStatus(filter.value)
    }
  })
}

</script>
