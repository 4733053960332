<template>
  <div :class="classes" class="vue-form">
    <div v-if="title" class="formTitle" v-html="wrappedTitle"></div>
    <template v-for="{content, type} in messages">
      <div :class="type" class="generalFormMessages">
        <div class="message" v-html="content"></div>
      </div>
    </template>
    <form :class="formDetails.classes" @submit.prevent="handleSubmit">
      <template v-for="{data: field} in processedFormFields.filter(isSingleField)"
                :key="field.id"
                class="form-group">
        <component :is="`${field.type}Field`"
                   ref="inputRefs"
                   :class="classResolver(field.classes)"
                   :model="formFields[field.name].value || ''"
                   v-bind="field"
                   @update:model="(e) => handleUpdate(field, e)"
        />
      </template>
      <template v-for="{data: group} in processedFormFields.filter(isFieldGroup)" :key="group.id"
                class="form-group">
        <div class="row">
          <component :is="field.type + 'Field'"
                     v-for="field in group"
                     ref="inputRefs"
                     :class="classResolver(field.classes)"
                     v-bind="field"
                     @update:model="(e) => handleUpdate(field, e, 'fieldgroup')"
          />
        </div>
      </template>
      <template
          v-for="{data: fieldset} in processedFormFields.filter(fieldset => isFieldSet(fieldset) && !isHolding(fieldset))"
          :key="fieldset.id"
          class="form-group"
      >
        <fieldset :class="fieldsetSetting(fieldset, 'fieldset_classes')" class="row">
          <legend class="row" v-html="buildFieldsetLegend(fieldset)"/>
          <div
              :id="fieldset.id"
              :class="isCollapsible(fieldsetSetting(fieldset, 'is_collapsable'), fieldsetSetting(fieldset, 'is_collapsed'))"
          >
            <template v-for="(child, childKey) in fieldset" :key="child.id" class="form-group">
              <component :is="`${child.type}Field`"
                         ref="inputRefs"
                         :class="classResolver(child.classes)"
                         v-bind="child"
                         @update:model="(e) => handleUpdate(child, e, 'fieldset')"
                         v-if="isSingleField(child)"
              />
            </template>
            <template v-for="(childGroup, childGroupKey) in fieldset" :key="childGroup.id"
                      class="form-group">
              <div class="row" v-if="childGroupKey.includes('fieldGroup')">
                <component v-for="(groupField, groupFieldName) in childGroup"
                           :is="groupField.type + 'Field'"
                           ref="inputRefs"
                           :class="classResolver(groupField.classes)"
                           v-bind="groupField"
                           @update:model="(e) => handleUpdate(groupField, e, 'fieldgroup')"
                />
              </div>
            </template>
          </div>
        </fieldset>
      </template>

      <div :class="formDetails.clearButton ? 'with-clear' : ''" class="formButtons">
        <button
            v-if="(!formDetails?.removeSubmit)"
            :disabled="isSubmitting"
            class="btn btn-primary"
            type="submit"
        >
          <img v-if="isSubmitting" alt="loading" class="form-submit loader-spinner"
               src="/assets/icons/loader.gif">
          <span v-else>{{ formDetails.submit }}</span>
        </button>
        <button v-if="(formDetails?.clearButton)"
                :disabled="isSubmitting"
                class="btn btn-primary"
                type="button"
                @click="clearFormFields"
        >
          {{ formDetails.clearButtonText }}
        </button>
      </div>

    </form>
  </div>
</template>

<script>
import objectHash from 'object-hash';
import {computed, onMounted, ref, watch} from 'vue';
import commonProps from '@/_core/components/_properties/common'
import classResolver from "@/_core/services/classResolver";
import useApi from "@/_core/services/api";
import Global from "@/_core/services/global";
import {useRoute} from "vue-router";
import useTokenStore from "@/_core/store/useTokenStore";
import useApiResponseProcessor from "@/_core/services/ApiResponseProcessor";
import useParamsInHash from "@/_core/services/paramsInHash";
import {isCollapsible} from "@/_core/mixins/classFunctions";
import useFilterStore from "@/_core/store/useFilterStore";

export default {
  methods: {isCollapsible},
  props: {
    ...commonProps,
    dataSrc: {
      type: String,
      required: true,
    },
    header: {
      type: Number,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    buildSubmitOnly: {
      type: Boolean,
      default: false,
    },
    persistFilters: {
      type: Boolean,
      default: false,
    },
    isShared: {
      type: Boolean,
      default: false,
    },
    components: {
      type: Array,
      default: () => [],
    }
  },
  emits: ['refresh'],
  setup(props, {emit}) {
    const Api = useApi();

    const inputRefs = ref([]);

    const route = useRoute();
    const dataSrc = ref('');

    const formFields = ref({});
    const formDetails = ref({});
    const isSubmitting = ref(false);
    const messages = ref([]);
    const method = computed(() => formDetails.value?.verb || 'post');

    const classes = classResolver(props.classes);

    const buildSubmitOnly = props.buildSubmitOnly;

    const wrappedTitle = computed(() => `<h${props.header}>${props.title}</h${props.header}>`);

    const processedFormFields = computed(() => processFormFields(formFields.value));

    const {setFilterParams} = useFilterStore()
    const {paramsToHash, paramsFromHash} = useParamsInHash()

    const {processResponse, processError} = useApiResponseProcessor(() => emit('refresh'));


    function clearFormFields() {
      paramsToHash({});
      setFilterParams({});

      Object.entries(formFields.value).forEach(components => components.forEach(component => {
        if (typeof component === 'string') {
          const element = document.getElementById(component);
          if (element) {
            element.value = null;
          }
        }
      }))
      this.myFormFields = this.formFields;
    }

    function isSingleField(field) {
      return !isFieldSet(field) && !isFieldGroup(field);
    }

    function isFieldSet(field) {
      const name = typeof field === 'string' ? field : (field.name || '');
      if (typeof name !== 'string') return false;
      return name.includes('fieldset');
    }

    function isHolding(field) {
      return field.inputType === 'holding';
    }

    function isFieldGroup(field) {
      const name = typeof field === 'string' ? field : (field.name || '');
      if (typeof name !== 'string') return false;
      return name.includes('fieldGroup');
    }

    function buildSubmitDataObject() {
      //the form we want is simply name.value
      let data = {};
      // if (typeof this.myFormFields === 'undefined') {
      //   this.myFormFields = this.formFields;
      // }


      Object.entries(formFields.value).forEach(([name, field]) => {
        if (isFieldSet(name)) {
          Object.values(field).forEach((child) => {
            if (isFieldGroup(field)) {
              Object.values(child).forEach((groupField) => {
                data = {
                  ...data,
                  ...buildSubmitDataFromFields(groupField)
                };
              });
            } else {
              data = {
                ...data,
                ...buildSubmitDataFromFields(child)
              };
            }
          });
        } else if (isFieldGroup(name)) {
          Object.values(field).forEach((groupField) => {
            data = {
              ...data,
              ...buildSubmitDataFromFields(groupField)
            };
          });
        } else {
          data = {
            ...data,
            ...buildSubmitDataFromFields(field)
          };
        }
      });

      // clean string data by trimming excess whitespace
      Object.entries(data).forEach(([key, value]) => {
        if (typeof value === 'string') {
          data[key] = value.trim();
        }
      });

      return data;
    }

    function convertToFilters()
    {

    }

    function buildSubmitDataFromFields(fieldData) {

      const shouldInclude = () => fieldData.hasOwnProperty('name') && !['link', 'markup'].includes(fieldData.type)
      if (!shouldInclude()) {
        return {};
      }

      if (fieldData.inputType === 'file') {
        return {
          [fieldData.name]: {
            type: 'file',
            file: fieldData.file
          }
        };
      }
      if (fieldData.inputType === 'checkbox') {
        return {
          [fieldData.name]: (fieldData.hasOwnProperty('value') ? fieldData.value : true)
        };
      }

      if (fieldData.value && fieldData.value.hasOwnProperty('key')) {
        return {[fieldData.name]: fieldData.value.key};
      }

      return {[fieldData.name]: fieldData.value}
    }

    function getFieldsetSetting(fieldset, setting) {
      if (formFields.value[fieldset]?.fieldset_settings) {
        return formFields.value[fieldset].fieldset_settings[setting] ? formFields.value[fieldset].fieldset_settings[setting] : '';
      } else {
        return '';
      }
    }

    function buildFieldsetLegend(fieldset) {

      let content = '<div';
      const wrapperClasses = fieldsetSetting(fieldset, 'fieldset_legend_wrapper_classes');
      const wrapperContent = fieldsetSetting(fieldset, 'fieldset_legend');
      const isCollapsable = fieldsetSetting(fieldset, 'is_collapsable');
      const isCollapsed = fieldsetSetting(fieldset, 'is_collapsed');
      const collapsedLink = fieldsetSetting(fieldset, 'collapsed_link');
      const collapsedButton = fieldsetSetting(fieldset, 'collapsed_button');
      const collapsedLinkText = fieldsetSetting(fieldset, 'collapsed_lb_text');

      let overview = getFieldsetSetting(fieldset, 'overview');

      if (wrapperClasses !== '') {
        content += ` class="${wrapperClasses}"><div`;
      }

      content += ` class="col-sm-${isCollapsable ? 8 : 10}"`;

      if (wrapperContent !== '') {
        content += `>${wrapperContent}</div>`;
      }
      if (isCollapsable) {
        content += '<div class="col-sm-1">'
        let collapsedClass = '';
        if (isCollapsed) {
          collapsedClass = ' collapsed';
        }
        if (collapsedLink) {
          content += `<a class="fieldset-collapse-link${collapsedClass}" data-toggle="collapse" href="#${fieldset}" role="button" aria-expanded="false" aria-controls="${fieldset}">${collapsedLinkText}</a>`;
        }

        if (collapsedButton) {
          content += `<button class="btn btn-primary${collapsedClass}" type="button" data-toggle="collapse" data-target="#${fieldset}" aria-expanded="false" aria-controls="${fieldset}">${collapsedLinkText}</button>`;
        }
        content += '</div>';
      }
      if (overview) {
        content += '<div class="col-md-2">';
        //@todo get overview content via callback method
        content += '</div>';
      }
      content += '</div>';

      return content;
    }

    watch(dataSrc, async () => {
      await getFormData();
    })

    watch(formFields, (to, from) => {
      if (objectHash(to) !== objectHash(from)) {
        messages.value = [];
      }
    })

    onMounted(async () => await getFormData());

    async function handleSubmit() {
      try {
        await Global.checkAuthenticated(route.path)
        //if we can, build the page

        isSubmitting.value = true;
        const data = buildSubmitDataObject();

        // allow us to build the submit object without submitting the form directly.
        if (buildSubmitOnly) {
          isSubmitting.value = false;
          setFilterParams(data);

          return data
        }

        let invalid = false;
        //loop over $refs to check for invalid form fields.
        // for (let field in this.$refs) {
        //   if (typeof this.$refs[field][0] !== 'undefined' && typeof this.$refs[field][0].$v !== 'undefined') {
        //     this.$refs[field][0].$v.$touch()
        //     if (this.$refs[field][0].$v.$invalid) {
        //       invalid = true;
        //     }
        //   }
        // }

        if (invalid) {
          isSubmitting.value = false;
          messages.value = [
            {
              content: `<span class='error' data-event='${this.randomString()}'>There are errors in your submission, please check the highlighted red fields.</span>`,
              type: 'error',
            }
          ];
          return data;
        }

        const {accessToken} = useTokenStore()
        const formData = new FormData();
        Object.entries(data).forEach(([field, value]) => {
          formData.append(field, value?.file || value);
        });
        formData.append('_METHOD', method.value);
        const formRequest = {
          url: formDetails.value.action,
          method: 'post',
          data: formData,
          headers: {
            'content-type': 'multipart/form-data',
            "Authorization": `Bearer ${accessToken}`
          }
        }
        try {
          const response = await Api.request(formRequest)
          await processResponse(response);

          if (typeof this.onSubmissionComplete !== 'undefined') {
            this.onSubmissionComplete(response.data);
          }
        } catch (error) {
          messages.value = await processError(error);
        } finally {
          isSubmitting.value = false;
        }
      } catch (error) {
        console.error(error);
      }
    }

    function fieldsetSetting(fieldset, setting) {

      const settings = fieldset?.fieldset_settings;
      if (settings) {
        return settings[setting] || '';
      }
      return '';
    }

    function handleUpdate(field, event, fieldType=false) {

      if (fieldType == 'fieldset' || fieldType == 'fieldgroup') {
        Object.entries(formFields.value).forEach(([key, fieldVal]) => {
          if (typeof fieldVal === 'object') {
            Object.entries(fieldVal).forEach(([key2, fieldVal2]) => {
              if (typeof fieldVal2 === 'object' && fieldVal2.hasOwnProperty('name') && fieldVal2.name == field.name) {
                formFields.value[key][key2].value = field.inputType === 'checkbox' ? event.target.checked : event.target.value;
              }
            });
          }
        });
      } else {
        formFields.value[field.name].value = field.inputType === 'checkbox' ? event.target.checked : event.target.value;
      }
    }

    return {
      classes,
      messages,
      wrappedTitle,
      formDetails,
      isSubmitting,
      processedFormFields,
      isSingleField,
      isFieldGroup,
      isFieldSet,
      isHolding,
      classResolver,
      fieldsetSetting,
      method,
      handleSubmit,
      buildFieldsetLegend,
      formFields,
      handleUpdate,
      clearFormFields,
      inputRefs,
    }

    function processFormFields(fields) {
      const hash = paramsFromHash();
      const mapVal = (key, val) => {
        if (typeof val !== 'object') {
          return val;
        }

        const mapped = {...val}

        if (mapped.hasOwnProperty('name')) {
          mapped.value = hash[val.name];

          if (!mapped.value) {
            mapped.value = '';

            if (typeof val.value !== 'undefined') {
              mapped.value = val.value;
            }
          }

          return mapped;
        }

        Object.entries(mapped).forEach(([key, val]) => mapped[key] = mapVal(key, val));

        return mapped;
      }

      const processed = []
      Object.entries(fields).forEach(([name, val]) => processed.push({
        name,
        data: mapVal(name, val)
      }));

      return processed;
    }

    function onSubmissionComplete(data) {
      if (typeof data.reload != "undefined") {
        this.dataSource = this.dataSource + '?' + Date.now();
        //this.$forceUpdate();
        //this.forceRerender();
      }

      return true;
    }

    async function getFormData() {
      try {
        isSubmitting.value = true;
        const response = await Api.get(props.dataSrc);
        const data = await processResponse(response);
        formFields.value = data.form.fields;
        formDetails.value = data.form.details;
      } catch (error) {
        console.error(error);
      } finally {
        isSubmitting.value = false;
      }
    }
  }
}


</script>

<style lang="scss">
.generalFormMessages {
  &.error {
    .message {
      margin-bottom: 20px;
      background: $form--error--color;
      padding: 10px;
      color: $brand--c1--hover-text;
    }
  }
}

form {
  .form-input--error {
    outline-color: $form--error--color;
    outline-style: solid;
  }
}

.form-submit {
  &.loader-spinner {
    width: 20px;
  }
}
</style>
