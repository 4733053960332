import FontAwesomeIcon from "@/components/FontAwesomeIcon.vue";
import {createApp} from "vue";

export default function (cell) {
  if (cell.getValue() === null) {
    return '';
  }
  const data = cell.getValue();

  const div = document.createElement("div");
  const instance = createApp(FontAwesomeIcon, data).mount(div);

  return instance.$el;
}
