<template>
  <div class="form-field-container input-field-container" :class="classes">
    <div v-if="inputType === 'holding'"/>

    <div v-else
         :class="[
               (inputType !== 'hidden' || label) ? 'form-group' : 'hidden-field-wrapper',
            ]">
      <label v-if="label"
             :class="inputType !== 'date' ? 'bmd-label-floating' : ''"
             :for="id"
             class="form__label">{{ label }}{{ required ? '*' : '' }}</label>
      <div v-if="icon" class="input-group-addon" v-html="icon"></div>
        <input :id="id"
               :disabled="disabled"
               :name="fieldName"
               :placeholder="placeholder"
               :type="inputType"
               class="form-control form__input"
               :class="{'form-input--error': !valid && isSubmitted}"
               @input="$emit('update:model', $event)"
               v-model="fieldValue"
               @change="saveValue()"
        />
      <span v-if="!valid && isSubmitted" class="error text-error">{{ validationError }}</span>
    </div>
    <div v-if="help" class="form-text">{{ help }}</div>
  </div>
</template>

<script setup>
import {computed, onMounted, ref} from "vue";
import classResolver from "@/_core/services/classResolver";
import classesProp from '@/_core/components/_properties/classes';
import useFormStore from "@/_core/store/useFormStore";
import formFields from "../_properties/formFields";
import isValid from "@/_core/services/isValid";

let valid = ref(true);
let validationError = ref('');

const props = defineProps({
  ...classesProp,
  ...formFields,
  inputType: {
    type: String,
    required: true
  },
  type: {
    type: String,
    default: 'input'
  },
  icon: {
    type: String,
    default: '',
  },
});

const fieldValue = ref(props.value)

const classes = computed(() => classResolver({
  [props.classes]: true,
  'form-check-wrapper': props.inputType === 'checkbox',
}))


const formStore = useFormStore();
const isSubmitted = ref(false);
formStore.$subscribe((mutation, state) => { // Subscribe to formSubmission via formStore
  isSubmitted.value = formStore.isFormSubmitted(props.formId);
})

function saveValue() {
  const {validCheck, validError} = isValid(props.fieldName, props.validations, fieldValue.value);
  valid.value = validCheck;
  validationError.value = validError;
  const {setField} = formStore;
  setField(props.formId, props.fieldName, fieldValue.value, validCheck, validationError);
}

onMounted(() => { // Initialise field in formStore - important
  saveValue();
})

</script>


