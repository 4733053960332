<template>
  <div :class="{
    'beatingHeart--animate': animate
  }" :style="{
    '--bh-color': colour,
    '--bh-rate': rateSecs,
  }" class="beatingHeart">

    <Heart class="beatingHeart__heart"/>
    <div class="beatingHeart__content">
      <div v-for="item in content">{{ item }}</div>
    </div>
  </div>
</template>

<script setup>

import {computed, defineProps} from 'vue';
import Heart from "@/components/Heart.vue";

const props = defineProps({
  content: {
    type: Array,
    required: false,
    default: []
  },
  colour: {
    type: String,
    required: true
  },
  rate: {
    type: Number,
    default: 0
  },
  animate: {
    type: Boolean,
    default: true
  }
});

const rateSecs = computed(() => {
  const rateSecs = 0.6 + (100 - props.rate / 100) * .012;

  return `${rateSecs.toFixed(2)}s`;
});

</script>

<style lang="scss">
.beatingHeart {
  $self: &;

  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  position: relative;
  min-width: 100px;
  text-align: center;

  &__content {
    position: absolute;
    top: 45%;
    transform: translateY(-50%);
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px rgba(0, 0, 0, 0.6);
    text-align: center;
  }

  &__heart {
    transform: scale(1);
    width: 100%;
    color: var(--bh-color);

    @keyframes beat {
      5% {
        transform: scale(1);
      }
      10% {
        transform: scale(1.1);
      }
      15% {
        transform: scale(1);
      }
      25% {
        transform: scale(1);
      }
      35% {
        transform: scale(1.2);
      }
      45% {
        transform: scale(1);
      }
    }

  }

  &--animate {
    #{ $self }__heart {
      animation: beat var(--bh-rate) infinite ease-out;
    }
  }
}
</style>
